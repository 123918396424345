@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  background-color: #414042;
}

.widgetContainer {
  background-color: #efefef;
  height: 100px;
  width: 215px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.calcContainer {
  padding: 50px 0;
  max-width: 1040px;
  margin: 0 auto;
}
.extraCostContainer {
  max-width: 1000px;
  display: grid;
  grid-template-columns: 60% 40%;
}
.inputContainer {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 215px 215px 493px;
  max-width: 960px;
  margin: 20px 20px 10px 20px;
  gap: 15px;
}
.disclaimerContainer {
  display: grid;
  grid-template-columns: 65% 35%;
  margin: 0 20px;
}
.headingContainer {
  background: #f1f1f2;
  padding: 10px 20px 10px 40px;
  display: grid;
  grid-template-columns: 60% 40%;
  align-items: center;
}
.bannerContainer {
  display: grid;
  grid-template-columns: 11% 89%;
  justify-content: center;
  align-items: top;
  gap: 20px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}
@media screen and (max-width: 1024px) {
  .inputContainer {
    grid-template-columns: 225px;
    justify-content: center;
  }
  .widgetContainer {
    width: 225px;
    text-align: center;
  }
  .inputContainer p {
    font-size: 12px;
  }
  .disclaimerContainer {
    display: block;
    text-align: center;
  }
  .bannerContainer {
    display: none;
  }
  .headingContainer {
    grid-template-columns: 100%;
  }
}
